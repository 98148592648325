.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background: var(--background-color);
  height: var(--navbar-height-small);
  font-family: var(--font-family-nav);
  /* border-bottom: 1px solid var(--border-color); */
  border-bottom: 2px dashed var(--border-color);
}

.nav-emoji-pine {
  color: var(--green);
}

.container .logo {
  filter: var(--svg-color-filter);
  width: 36px;
  height: 36px;
  margin-right: 0.5rem;
}

.navigation .container {
  display: flex;
  justify-content: space-between;
  height: var(--navbar-height-small);
}

.navigation .nav-wrapper {
  display: flex;
}

.navigation nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  max-width: 350px;
  margin-left: 0.5rem;
}

.navigation a,
.navigation a:hover {
  text-decoration: none;
  background: transparent;
}

.navigation a.item,
.navigation button.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.15rem;
  gap: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: 500;
  color: var(--navbar-color);
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  white-space: nowrap;
}

.navigation a.item.brand {
  font-size: 1rem;
  font-weight: 800;
  color: var(--font-color-bright);
  line-height: 1;
  padding: 0;
}

.navigation a.item.brand:hover {
  border-color: transparent;
}

.navigation a.item.brand span {
  display: none;
  white-space: nowrap;
}

@media screen and (min-width: 500px) {
  .navigation nav {
    margin-left: 1.5rem;
  }

  .navigation a.item.brand {
    gap: 0.6rem;
  }

  .navigation a.item.brand span {
    display: block;
  }
}

.navigation a.item.brand:hover {
  background: transparent;
}

.navigation a.item:hover,
.navigation button.item:hover {
  color: var(--navbar-color-hover);
}

.navigation a.item.active,
.navigation button.item.active {
  /* color: var(--primary-color); */
  color: var(--navbar-highlight);
}

.navigation .nav-item-outer {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.navigation .nav-image {
  display: none;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
}

.theme-toggle button {
  padding: 0.4rem;
  margin-left: 0.5rem;
}

.theme-toggle button img {
  margin: 0;
}

@media screen and (min-width: 700px) {
  .navigation {
    padding: 1.25rem 0;
    /* position: static; */
    height: auto;
  }

  .navigation,
  .navigation .container {
    height: auto;
  }

  .navigation nav {
    width: auto;
    max-width: 100%;
    margin-left: 1.5rem;
  }

  .navigation .nav-wrapper {
    display: block;
  }

  .navigation .nav-image {
    display: block;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
  }

  .navigation a.item {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
  }

  .navigation a.item.brand {
    display: inline-flex;
    font-size: 1.15rem;
    padding: 0;
    gap: 0.75rem;
  }

  .navigation a.item.brand span,
  .theme-toggle span {
    display: block;
  }

  .navigation a.item.brand:hover {
    background: transparent;
  }

  .navigation a.item:hover,
  .navigation button.item:hover {
    /* color: var(--navbar-color-hover); */
    color: var(--navbar-highlight);
    border: 1px solid transparent;
  }

  .navigation a.item.active,
  .navigation button.item.active {
    background-color: var(--button-background-color);
    border-color: var(--button-border-color);
    /* color: var(--navbar-color-hover); */
    color: var(--navbar-highlight);
  }

  .navigation a.item.active:hover,
  .navigation button.item.active:hover {
    /* color: var(--navbar-color-hover); */
    color: var(--navbar-highlight);
  }

  .theme-toggle button {
    padding: 0.5rem;
    margin-left: 0.5rem;
  }
}
