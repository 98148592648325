.footer {
  padding: 0 0;
  font-family: var(--post-font-family);
}

.footer section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.footer img {
  display: inline-block;
  max-height: 28px;
  width: auto;
}

.footer nav {
  display: flex;
  gap: 1rem;
  margin: 0 1rem;
}

.footer nav img {
  display: inline-block;
  max-height: 18px;
  width: auto;
}

.footer a,
.footer span {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: var(--font-color-base);
  font-size: 0.9rem;
  text-decoration-line: none;
}

.footer a:hover span {
  color: var(--footer-highlight);
}

.footer a:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.footer a.button {
  color: var(--font-color-base);
}

.footer a.button:hover {
  color: var(--yellow);
  text-decoration: none;
}

.footer span.ack {
  color: var(--font-color-base);
  font-style: italic;
  font-size: 1.1rem;
  font-weight: 800;
}

.footer nav.ack {
  display: flex;
  gap: 1.5rem;
  /* margin: 0 10rem; */
  max-width: 460px;
  padding: 1rem 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 2px dashed var(--border-color);
}

.footer nav.ack img {
  height: 25px;
  width: auto;
}

.footer nav.ack img.special {
  filter: var(--footer-special-filter);
}

.footer span.banner {
  display: flex;
  gap: 2rem;
  padding-bottom: 1rem;
}

.footer .icon {
  filter: var(--svg-color-filter);
  width: 30px;
  height: 30px;
}

.footer span.split {
  margin-top: 0rem;
  margin-bottom: 2rem;
  width: 100%;
  border-top: 2px dashed var(--border-color);
  align-self: stretch;
}

.footer nav.morelink {
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .footer nav.morelink {
    margin-bottom: 1rem;
  }

  nav.morelink a.button.small {
    padding: 0.2rem 0.3rem;
  }

  nav.morelink a.button.small span {
    font-size: 0.8rem;
  }
}
