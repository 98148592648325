.blog-content {
  max-width: 100%;
  min-width: 0;
}

a {
  color: var(--blog-post-item-color);
  text-decoration: none;
}

/* Posts */

a.post {
  display: flex;
  gap: 1rem;
  padding: 0.4rem 0;
  background: transparent;
  text-decoration: none;
  border-bottom: 1px solid var(--border-color);
}

a.post:last-of-type {
  border: none;
}

a.post:hover,
a.post:hover h3,
a.post:hover time {
  color: var(--font-color-bright);
}

a.post:hover h3 {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

a.post h3 {
  margin: 0;
  font-size: 1rem;
  color: var(--font-color);
  font-weight: 500;
  font-family: var(--font-family-base);
  line-height: 1.3;
}

a.post time {
  display: none;
  color: var(--post-time-color);
  margin-left: auto;
}

a.post time.new-post {
  font-weight: 600;
  color: var(--new-text);
}

.new-post-pill {
  font-family: var(--font-family-base);
  display: inline-block;
  background: var(--new-background);
  color: var(--gray-10);
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 1;
  font-weight: 600;
  font-size: 0.8rem;
}

@media screen and (min-width: 700px) {
  .posts.newspaper {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    column-gap: 2rem;
  }

  a.post time {
    display: block;
  }

  a.post h3 {
    font-size: 1.1rem;
    font-weight: 600;
  }
}

.pagination {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.pagination * {
  display: flex;
  justify-content: center;
  flex: 1;
  max-width: 8rem;
}

.jump-to-page {
  display: flex;
}

.jump-to-page * {
  flex: 1;
}

.jump-to-page input {
  font-size: 1.15rem;
  font-family: var(--font-family-base);
  background: var(--button-background-color);
  text-align: center;
  color: var(--font-color-base);
  /* border-radius: var(--border-radius); */
  border-top-right-radius: none;
  border-bottom-right-radius: none;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  border: 1px solid var(--button-border-color);
}

.jump-to-page input:focus::placeholder {
  color: transparent;
}

.jump-to-page input:focus-visible {
  outline: none;
  color: var(--navbar-highlight);
  background: var(--input-background-highlight);
  /* outline-color: var(--navbar-highlight); */
  /* outline-width: 2px; */
  /* outline-style: solid; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* For WebKit browsers */
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
  appearance: textfield; /* For Firefox */
}

#goto {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  flex: 0;
}
